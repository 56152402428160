@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");

.screenshot {
  display: block;
  width: 100%;
}

.cards {
  display: flex;
  flex-direction: row;
}

#projects {
  display: flex;
  flex-direction: column;
  padding-top: 100px;
  font-family: "Montserrat", sans-serif;
  justify-content: center;
  align-items: center;
}

.projects-title {
  color: darkred;
  font-size: 30px;
  font-weight: 800;
  padding-bottom: 30px;
  align-self: flex-start;
}

.projects-container {
  display: flex;
  flex-direction: column;
}

.project-card {
  margin: 15px;
  position: relative;
  width: 300px;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.25s;
}

.overlay > * {
  transform: translateY(20px);
  transition: transform 0.25s;
}

.overlay:hover > * {
  transform: translateY(0);
}

.project-name {
  font-size: 2em;
}

.overlay:hover {
  opacity: 1;
}

@media screen and (max-width: 768px) {
  .cards {
    flex-direction: column;
  }
  .projects-title {
    align-self: center;
  }
}

