nav {
  width: 100vw;
  height: 10vh;
  min-height: 40px;
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: fixed;
}

.nav {
  width: 100vw;
  height: 10vh;
  display: inline-flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.link {
  color:#999;
  background:rgba(0, 0, 0, 0.8);
  padding:10px 20px;
  font-size:24px;
  text-decoration:none;
  letter-spacing:2px;
  text-transform:uppercase;
}

.link:hover {
  border:none;
  background:rgba(0, 0, 0, 0.4);
  background:#fff;
  padding:20px 20px;
  color: #1b1b1b;
}

@media screen and (max-width: 768px) {
  .link {
    font-size:18px;
    padding: 5px 10px;
  }
}

@media screen and (max-width: 450px) {
  .link {
    font-size: 12px;
  }
  .link:hover {
    padding:10px 10px;
  }
}