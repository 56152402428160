@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");

#contact {
  font-family: "Montserrat", sans-serif;
  margin-top: 100px;
}

.contact-title {
  color: darkred;
  font-size: 30px;
  font-weight: 800;
  padding-bottom: 30px;
  margin-top: 10px;
  align-self: center;
}

.comment-form {
  max-width: 450px;
	padding: 30px;
	margin: 30px auto;
	border-radius: 10px;
	border: 3px solid darkred;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.input-message {
  width: 100%;
	height: 100px;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 20px;
}

.input-email {
  width: 100%;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 20px;
}

.submit-button {
  font-family: "Montserrat", sans-serif;
  color:#999;
  background:rgba(0, 0, 0, 0.8);
  padding:10px 20px;
  font-size:18px;
  text-decoration:none;
  letter-spacing:2px;
  text-transform:uppercase;
}

