@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");

.HTML,
.CSS,
.JS,
.React,
.MongoDB,
.Node,
.Express,
.SQL,
.Ruby,
.Rails,
.GraphQL {
  max-height: 75px;
}
.about-title, .skills-title {
  color:darkred;
  font-size: 30px;
  font-weight: 800;
  padding-bottom: 30px;
}

.bio {
  display: flex;
  flex-direction: column;
  width: 50vw;
}
#about-me {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 75px;
  align-items: center;
  color: white;
  font-family: "Montserrat", sans-serif;
}

.about-container {
  display: flex;
  justify-content: space-between;
}

.face {
  border-radius: 80%;
  padding: 5px;
}

.about-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.hub, .linkedin {
  width: 50px;
  padding: 20px;
}

.about-content {
  font-size: 18px;
}

@media screen and (max-width: 768px) {
  .face {
    display: none;
  }
  .about-title, .skills-title {
    align-self: center;
  }
  .skills {
    display: flex;
    flex-direction: column;
    width: 90vw;
  }
  .bio {
    width: 70vw;
  }

}
