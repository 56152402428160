@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.screenshot {
  display: block;
  width: 100%;
}

.cards {
  display: flex;
  flex-direction: row;
}

#projects {
  display: flex;
  flex-direction: column;
  padding-top: 100px;
  font-family: "Montserrat", sans-serif;
  justify-content: center;
  align-items: center;
}

.projects-title {
  color: darkred;
  font-size: 30px;
  font-weight: 800;
  padding-bottom: 30px;
  align-self: flex-start;
}

.projects-container {
  display: flex;
  flex-direction: column;
}

.project-card {
  margin: 15px;
  position: relative;
  width: 300px;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.25s;
}

.overlay > * {
  -webkit-transform: translateY(20px);
          transform: translateY(20px);
  transition: -webkit-transform 0.25s;
  transition: transform 0.25s;
  transition: transform 0.25s, -webkit-transform 0.25s;
}

.overlay:hover > * {
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

.project-name {
  font-size: 2em;
}

.overlay:hover {
  opacity: 1;
}

@media screen and (max-width: 768px) {
  .cards {
    flex-direction: column;
  }
  .projects-title {
    align-self: center;
  }
}


#contact {
  font-family: "Montserrat", sans-serif;
  margin-top: 100px;
}

.contact-title {
  color: darkred;
  font-size: 30px;
  font-weight: 800;
  padding-bottom: 30px;
  margin-top: 10px;
  align-self: center;
}

.comment-form {
  max-width: 450px;
	padding: 30px;
	margin: 30px auto;
	border-radius: 10px;
	border: 3px solid darkred;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.input-message {
  width: 100%;
	height: 100px;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 20px;
}

.input-email {
  width: 100%;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 20px;
}

.submit-button {
  font-family: "Montserrat", sans-serif;
  color:#999;
  background:rgba(0, 0, 0, 0.8);
  padding:10px 20px;
  font-size:18px;
  text-decoration:none;
  letter-spacing:2px;
  text-transform:uppercase;
}


nav {
  width: 100vw;
  height: 10vh;
  min-height: 40px;
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: fixed;
}

.nav {
  width: 100vw;
  height: 10vh;
  display: inline-flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.link {
  color:#999;
  background:rgba(0, 0, 0, 0.8);
  padding:10px 20px;
  font-size:24px;
  text-decoration:none;
  letter-spacing:2px;
  text-transform:uppercase;
}

.link:hover {
  border:none;
  background:rgba(0, 0, 0, 0.4);
  background:#fff;
  padding:20px 20px;
  color: #1b1b1b;
}

@media screen and (max-width: 768px) {
  .link {
    font-size:18px;
    padding: 5px 10px;
  }
}

@media screen and (max-width: 450px) {
  .link {
    font-size: 12px;
  }
  .link:hover {
    padding:10px 10px;
  }
}
.footer-link {
  text-decoration: none;
}
.HTML,
.CSS,
.JS,
.React,
.MongoDB,
.Node,
.Express,
.SQL,
.Ruby,
.Rails,
.GraphQL {
  max-height: 75px;
}
.about-title, .skills-title {
  color:darkred;
  font-size: 30px;
  font-weight: 800;
  padding-bottom: 30px;
}

.bio {
  display: flex;
  flex-direction: column;
  width: 50vw;
}
#about-me {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 75px;
  align-items: center;
  color: white;
  font-family: "Montserrat", sans-serif;
}

.about-container {
  display: flex;
  justify-content: space-between;
}

.face {
  border-radius: 80%;
  padding: 5px;
}

.about-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.hub, .linkedin {
  width: 50px;
  padding: 20px;
}

.about-content {
  font-size: 18px;
}

@media screen and (max-width: 768px) {
  .face {
    display: none;
  }
  .about-title, .skills-title {
    align-self: center;
  }
  .skills {
    display: flex;
    flex-direction: column;
    width: 90vw;
  }
  .bio {
    width: 70vw;
  }

}

.landing {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.my-name {
  color: white;
  font-size: 65px;
  align-content: center;
  -webkit-animation: fadeIn linear 2s;
          animation: fadeIn linear 2s;
  padding-right: 50px;
}

.engineer {
  color: white;
  font-size: 65px;
  align-content: center;
  -webkit-animation: fadeIn linear 6s;
          animation: fadeIn linear 6s;
  padding-left: 50px;
}

.line {
  color: darkred;
  font-size: 65px;
  font-weight: 800;
  -webkit-animation: fadeIn linear 4s;
          animation: fadeIn linear 4s;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
 @media screen and (max-width: 768px) {
   .my-name, .line, .engineer {
     font-size: 30px;
   }
 }

 @media screen and (max-width: 450px) {
   .landing {
     flex-direction: column;
     justify-content: center;
     align-items: center;
   }
   .my-name, .line, .engineer {
    font-size: 25px;
  }
  .my-name {
    padding-right: 0;
  }
  .engineer {
    padding-left: 0;
  }
 }
.signin-form {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}
.portal {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  color: white;
}

.project-detail-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* height: 100vh; */
  font-family: "Montserrat", sans-serif;
  color: white;
  padding-top:100px;
}

.project-detail-image {
  width: 600px;
  padding-bottom: 20px;
}
.project-detail-name {
  font-size: 2em;
  padding-bottom: 20px;
}

.detail {
  max-width: 600px;
}

.button-container {
  display: flex;
  justify-content: center;
  padding-top: 30px;
}

.github-button, .deployed-button {
  padding: 10px;
  background: white;
  font-family: "Montserrat", sans-serif;
}

@media screen and (max-width: 768px) {

  .project-detail-image, .detail {
    width: 90vw;
  }
}
body {
  background-image: url("https://i.imgur.com/FjHjCmS.jpg");
  /* background-repeat: no-repeat; */
  background-attachment: fixed;
}

html {
  scroll-behavior: smooth;
}
